<mat-card
  id="card"
  class="mt-3"
  [ngStyle]="{ 'overflow-y': isFullScreen ? 'scroll' : '' }"
>
  <div class="d-flex justify-content-between" style="margin-bottom: 0.42rem">
    <div class="title-container">
      <div class="d-flex align-items-center justify-content-flex-start mb-1">
        <mat-icon class="mr-2">collections_bookmark</mat-icon>
        <mat-card-title class="m-0">
          <h6 class="mb-0">{{ 'pages.diary.root.title' | translate }}</h6>
        </mat-card-title>
      </div>
    </div>
    <div>
      <button
        *ngIf="!isFullScreen"
        mat-icon-button
        matTooltip="{{ 'pages.diary.root.full-screen' | translate }}"
        (click)="openFullscreen('card')"
      >
        <mat-icon aria-label="fullscreen">fullscreen</mat-icon>
      </button>

      <button
        *ngIf="isFullScreen"
        mat-icon-button
        matTooltip="{{ 'pages.diary.root.full-screen' | translate }}"
        (click)="closeFullscreen()"
      >
        <mat-icon aria-label="fullscreen_exit">fullscreen_exit</mat-icon>
      </button>

      <button
        mat-icon-button
        (click)="addReminderOrTask()"
        [matTooltip]="
          'pages.diary.root.create-new-reminder-tooltip' | translate
        "
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="refreshDiary()"
        [matTooltip]="'pages.diary.root.reload-tooltip' | translate"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>
  <div *ngIf="isLoading">
    <app-skeleton-diary></app-skeleton-diary>
  </div>

  <div *ngIf="!isLoading">
    <mat-card-content class="mt-3">
      <div class="calendar-header d-none d-md-flex">
        <button
          id="today-button"
          mat-button
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          {{ 'pages.diary.calendar.today' | translate }}
        </button>
        <div class="date-view" style="min-width: 280px">
          <button
            mat-icon-button
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            <mat-icon aria-label="chevron_left" style="margin-bottom: 4px"
              >chevron_left</mat-icon
            >
          </button>

          <span>{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</span>

          <button
            mat-icon-button
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            <mat-icon aria-label="chevron_right" style="margin-bottom: 4px"
              >chevron_right</mat-icon
            >
          </button>
        </div>
        <mat-button-toggle-group>
          <mat-button-toggle
            (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month"
            >{{ 'pages.diary.calendar.month' | translate }}</mat-button-toggle
          >
          <mat-button-toggle
            (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week"
            >{{ 'pages.diary.calendar.week' | translate }}</mat-button-toggle
          >
          <mat-button-toggle
            (click)="setView(CalendarView.Day)"
            [class.active]="view === CalendarView.Day"
            >{{ 'pages.diary.calendar.day' | translate }}</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <div class="calendar-header d-flex d-md-none">
        <button
          id="today-button"
          mat-button
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          {{ 'pages.diary.calendar.today' | translate }}
        </button>
        <mat-button-toggle-group>
          <mat-button-toggle
            (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month"
            >{{ 'pages.diary.calendar.month' | translate }}</mat-button-toggle
          >
          <mat-button-toggle
            (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week"
            >{{ 'pages.diary.calendar.week' | translate }}</mat-button-toggle
          >
          <mat-button-toggle
            (click)="setView(CalendarView.Day)"
            [class.active]="view === CalendarView.Day"
            >{{ 'pages.diary.calendar.day' | translate }}</mat-button-toggle
          >
        </mat-button-toggle-group>
        <div class="date-view">
          <button
            mat-icon-button
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            <mat-icon aria-label="chevron_left" style="margin-bottom: 4px"
              >chevron_left</mat-icon
            >
          </button>

          <span>{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</span>

          <button
            mat-icon-button
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            <mat-icon aria-label="chevron_right" style="margin-bottom: 4px"
              >chevron_right</mat-icon
            >
          </button>
        </div>
      </div>
      <br />
      <div [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="CalendarView.Month"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (dayClicked)="dayClicked($event.day)"
          (beforeViewRender)="updateCalendarEvents($event)"
          (dblclick)="onItemDoubleClick()"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          (beforeViewRender)="updateCalendarEvents($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          (beforeViewRender)="updateCalendarEvents($event)"
        >
        </mwl-calendar-day-view>
      </div>
    </mat-card-content>
  </div>
</mat-card>
